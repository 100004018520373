@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
}

img {
  max-width: none;
  height: auto;
  position: relative;
  z-index: 100;
}

@media only screen and (max-width: 980px) {
  img {
    max-width: 100%;
  }
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inconsolata', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 500;
  background-color: white;
  height: 90px;
  padding: 1.25rem 2.25rem;
}

section {
  position: relative;
  padding-top: 1rem;
  width: 100%;
  max-width: 1680px;
  height: 100%;
  margin: 0 auto;
}

.content-container {
  max-height: 100%;
  overflow-y: auto;
}

@media only screen and (min-width: 780px) {
  .content-container {
    max-height: calc(100vh - 90px);
    overflow-y: auto;
  }
}

/* Underline-Effect */

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0d0d0d;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Nav */

.nav-btn {
  padding: 10px 5px;
  cursor: pointer;
  display: none;
  font-size: 1.5rem;
}

@media only screen and (max-width: 912px) {
  .nav-btn {
    display: block;
    right: 0;
  }

  nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
    background-color: #fff;
    transition: 1s;
    transform: translateX(+100vh);
    z-index: 500;
  }

  .responsive_nav {
    transform: none;
  }

  .nav-close-btn {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 1.9rem;
  }

  nav a {
    font-size: 1.25rem; /* 16px */
    line-height: 1.5rem; /* 24px */
  }
}
